import React from 'react';
import { string, number } from 'prop-types';

import { PageSection } from 'site/shared';
import styles from './Colors.module.css';

const colors = {
  primary: 7,
  success: 7,
  warning: 7,
  error: 7,
  info: 7,
  neutral: 11,
};
const capitalize = text => text[0].toUpperCase() + text.slice(1);

const Shade = ({ color, shade }) => (
  <span
    className={styles.color}
    style={{ backgroundColor: `var(--color-${color}-${shade})` }}
  />
);
Shade.propTypes = {
  color: string.isRequired,
  shade: number.isRequired,
};

const ColorSwatch = ({ color, shades }) => (
  <div className={styles.swatch}>
    {[...new Array(shades).keys()]
      .map(n => (n + 1) * 100)
      .map(shade => (
        <Shade key={`${color}-${shade}`} color={color} shade={shade} />
      ))}
  </div>
);
ColorSwatch.propTypes = {
  color: string.isRequired,
  shades: number.isRequired,
};

export default () => (
  <PageSection title="Colors">
    {Object.entries(colors).map(([color, shades]) => (
      <>
        <p className={styles.colorName}>{capitalize(color)}</p>
        <ColorSwatch color={color} shades={shades} />
      </>
    ))}
  </PageSection>
);
