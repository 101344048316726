import React from 'react';
import { node } from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './NavBarLink.module.css';

export const NavBarLink = ({ children, ...props }) => (
  <NavLink {...props} className={styles.link} activeClassName={styles.active}>
    {children}
  </NavLink>
);

NavBarLink.propTypes = {
  children: node.isRequired,
};
