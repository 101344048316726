import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { IconChevron } from 'lib';
import styles from './Select.module.css';

export const Select = ({ options, ...props }) => (
  <span className={styles.container}>
    <select {...props} className={styles.select}>
      {options.map(({ value: v, label }) => (
        <option value={v} key={v}>
          {label}
        </option>
      ))}
    </select>
    <IconChevron className={styles.arrow} />
  </span>
);

Select.propTypes = {
  options: arrayOf(
    shape({
      value: string,
      label: string,
    }),
  ).isRequired,
};
