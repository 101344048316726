import React from 'react';

import { Table } from 'lib';
import { ComponentExample, PageTitle } from 'site/shared';

export default () => (
  <>
    <PageTitle>Table</PageTitle>

    <ComponentExample
      snippet={`
        <Table
          columns={['Company', 'Symbol', 'Stock Price', 'Change']}
          rows={[
            ['Google', 'GOOG', 1090.99, '4.13%'],
            ['Apple', 'AAPL', 157.76, '13.05%'],
            ['Microsoft', 'MSFT', 107.17, '-8.13%'],
            ['Amazon', 'AMZN', 1670.57, '12.43%'],
            ['Facebook', 'FB', 149.01, '-5.17%'],
          ]}
        />
    `}
    >
      <Table
        columns={['Company', 'Symbol', 'Stock Price', 'Change']}
        rows={[
          ['Google', 'GOOG', 1090.99, '4.13%'],
          ['Apple', 'AAPL', 157.76, '13.05%'],
          ['Microsoft', 'MSFT', 107.17, '-8.13%'],
          ['Amazon', 'AMZN', 1670.57, '12.43%'],
          ['Facebook', 'FB', 149.01, '-5.17%'],
        ]}
      />
    </ComponentExample>
  </>
);
