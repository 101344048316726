import React from 'react';

import { Checkbox, Spacer } from 'lib';
import { ComponentExample, PageSection } from 'site/shared';

export default () => (
  <>
    <PageSection title="Checkboxes">
      <ComponentExample
        title="Normal"
        snippet={`
          <Checkbox label="Unchecked" />
          <Checkbox label="Checked" checked />
          <Checkbox label="Indeterminate" indeterminate />
          <Checkbox label="Disabled" disabled />
          <Checkbox label="Disabled and checked" disabled checked />
        `}
      >
        <Spacer direction="column">
          <Checkbox label="Unchecked" />
          <Checkbox label="Checked" defaultChecked />
          <Checkbox label="Indeterminate" indeterminate />
          <Checkbox label="Disabled" disabled />
          <Checkbox label="Disabled and checked" disabled defaultChecked />
        </Spacer>
      </ComponentExample>

      <ComponentExample
        title="Contained"
        snippet={`
          <Checkbox label="Unchecked" variant="contained" />
          <Checkbox label="Checked" variant="contained" checked />
          <Checkbox label="Indeterminate" variant="contained" indeterminate />
          <Checkbox label="Disabled" variant="contained" disabled />
          <Checkbox label="Disabled and checked" variant="contained" disabled checked />
        `}
      >
        <Spacer direction="column">
          <Checkbox label="Unchecked" variant="contained" />
          <Checkbox label="Checked" variant="contained" defaultChecked />
          <Checkbox label="Indeterminate" variant="contained" indeterminate />
          <Checkbox label="Disabled" variant="contained" disabled />
          <Checkbox
            label="Disabled and checked"
            variant="contained"
            disabled
            defaultChecked
          />
        </Spacer>
      </ComponentExample>
    </PageSection>
  </>
);
