import React from 'react';

import { Input, Spacer } from 'lib';
import { ComponentExample, PageSection } from 'site/shared';

export default () => (
  <>
    <PageSection title="Inputs">
      <ComponentExample
        title="States"
        snippet={`
          <Input value="Hello world" />
          <Input value="Disabled" disabled />
          <Input value="Read only" readOnly />
        `}
      >
        <Spacer size="medium">
          <Input defaultValue="Hello world" />
          <Input disabled defaultValue="Disabled" />
          <Input readOnly defaultValue="Read only" />
        </Spacer>
      </ComponentExample>
    </PageSection>
  </>
);
