import React from 'react';

import { Banner, Spacer } from 'lib';
import { ComponentExample, PageSection, PageTitle } from 'site/shared';

const variants = ['success', 'warning', 'error', 'info'];
const capitalize = text => text[0].toUpperCase() + text.slice(1);

export default () => (
  <>
    <PageTitle>Banner</PageTitle>

    <PageSection title="Variants">
      <ComponentExample
        snippet={`
          <Banner variant="success" title="Success" showIcon>
            {/* Lorem ipsum */}
          </Banner>

          <Banner variant="warning" title="Warning" showIcon>
            {/* Lorem ipsum */}
          </Banner>

          <Banner variant="error" title="Error" showIcon>
            {/* Lorem ipsum */}
          </Banner>

          <Banner variant="info" title="Info" showIcon>
            {/* Lorem ipsum */}
          </Banner>
        `}
      >
        <Spacer>
          {variants.map(variant => (
            <Banner
              key={variant}
              variant={variant}
              title={capitalize(variant)}
              showIcon
            >
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede.
            </Banner>
          ))}
        </Spacer>
      </ComponentExample>
    </PageSection>

    <PageSection title="No icon">
      <ComponentExample
        snippet={`
          <Banner variant="info" title="Info">
            {/* Lorem ipsum */}
          </Banner>
        `}
      >
        <Banner variant="info" title="Info">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
          Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
        </Banner>
      </ComponentExample>
    </PageSection>

    <PageSection title="No title">
      <ComponentExample
        snippet={`
          <Banner variant="info" showIcon>
            {/* Lorem ipsum */}
          </Banner>
        `}
      >
        <Banner variant="info" showIcon>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
          Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
        </Banner>
      </ComponentExample>
    </PageSection>

    <PageSection title="No icon or title">
      <ComponentExample
        snippet={`
          <Banner variant="info">
            {/* Lorem ipsum */}
          </Banner>
        `}
      >
        <Banner variant="info">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
        </Banner>
      </ComponentExample>
    </PageSection>
  </>
);
