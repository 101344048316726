import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import { PageTitle } from 'site/shared';
import Table from './sections/Table';
import Badge from './sections/Badge';
import Banner from './sections/Banner';
import Button from './sections/Button';
import Checkbox from './sections/Checkbox';
import Input from './sections/Input';
import RadioButton from './sections/RadioButton';
import Select from './sections/Select';

const routes = [
  { title: 'Table', href: '/components/table', render: Table },
  { title: 'Badge', href: '/components/badge', render: Badge },
  { title: 'Banner', href: '/components/banner', render: Banner },
  { title: 'Button', href: '/components/button', render: Button },
  {
    title: 'Checkbox',
    href: '/components/checkbox',
    render: Checkbox,
    exact: true,
  },
  { title: 'Input', href: '/components/input', render: Input },
  {
    title: 'RadioButton',
    href: '/components/radio-button',
    render: RadioButton,
    exact: true,
  },
  { title: 'Select', href: '/components/select', render: Select },
];

export const Components = () => (
  <>
    <PageTitle>Components</PageTitle>

    <ul>
      {routes.map(({ title, href, exact }) => (
        <li key={title}>
          <Link to={href} exact={exact}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
    <div>
      <Switch>
        {routes.map(({ title, href, render, exact }) => (
          <Route key={title} path={href} render={render} exact={exact} />
        ))}
      </Switch>
    </div>
  </>
);
