import React from 'react';
import { node, oneOf } from 'prop-types';

import styles from './Badge.module.css';

export const Badge = ({ children, variant }) => (
  <span
    className={styles.badge}
    style={{
      backgroundColor: `var(--color-${variant}-700)`,
      borderColor: `var(--color-${variant}-600)`,
      color: `var(--color-${variant}-200)`,
    }}
  >
    {children}
  </span>
);

Badge.propTypes = {
  variant: oneOf(['success', 'info', 'warning', 'error']).isRequired,
  children: node,
};

Badge.defaultProps = {
  children: undefined,
};
