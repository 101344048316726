import React from 'react';
import { node, oneOf, bool } from 'prop-types';
import classNames from 'classnames';
import {
  IconInfo,
  IconAlertCircle,
  IconCloseCircle,
  IconCheckCircle,
} from 'lib/Icon';

import styles from './Banner.module.css';

const getIcon = variant =>
  ({
    error: IconCloseCircle,
    info: IconInfo,
    success: IconCheckCircle,
    warning: IconAlertCircle,
  }[variant]);

export const Banner = ({ children, showIcon, title, variant }) => {
  const Icon = getIcon(variant);
  return (
    <section
      className={classNames(styles.banner, {
        [styles.withTitle]: title,
        [styles.withIcon]: showIcon,
      })}
      style={{
        backgroundColor: `var(--color-${variant}-700)`,
        borderLeftColor: `var(--color-${variant}-600)`,
        color: `var(--color-${variant}-200)`,
      }}
    >
      {showIcon && Icon && (
        <div className={styles.icon}>
          <Icon
            size="large"
            style={{
              color: `var(--color-${variant}-500)`,
            }}
          />
        </div>
      )}
      {title && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.body}>{children}</div>
    </section>
  );
};

Banner.propTypes = {
  variant: oneOf(['success', 'info', 'warning', 'error']).isRequired,
  children: node,
  showIcon: bool,
  title: node,
};

Banner.defaultProps = {
  children: undefined,
  showIcon: false,
  title: undefined,
};
