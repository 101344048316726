import React from 'react';
import { oneOf, bool } from 'prop-types';
import classNames from 'classnames';

import styles from './Input.module.css';

export const Input = ({ align, multiline, ...props }) => {
  const Element = multiline ? 'textarea' : 'input';
  return (
    <Element
      {...props}
      className={classNames(styles.input, styles[`align-${align}`], {
        [styles['single-line']]: !multiline,
        [styles.multiline]: multiline,
      })}
    />
  );
};

Input.propTypes = {
  align: oneOf(['start', 'center', 'end']),
  multiline: bool,
};

Input.defaultProps = {
  align: 'start',
  multiline: false,
};
