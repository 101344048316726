import React from 'react';

import { Select, Spacer } from 'lib';
import { ComponentExample, PageSection } from 'site/shared';

export default () => (
  <>
    <PageSection title="Selects">
      <ComponentExample
        snippet={`
          <Select
            options={[
              { value: 'ATL', label: 'Atlanta' },
              { value: 'BOS', label: 'Boston' },
              { value: 'CHI', label: 'Chicago' },
              { value: 'DEN', label: 'Denver' },
              { value: 'LA', label: 'Los Angeles' },
              { value: 'MIA', label: 'Miami' },
              { value: 'NYC', label: 'New York City' },
              { value: 'OMA', label: 'Omaha' },
              { value: 'ORL', label: 'Orlando' },
              { value: 'SF', label: 'San Francisco' },
            ]}
          />

          <Select options={[{ value: 'NYC', label: 'New York City' }]} disabled />

          <Select
            options={[
              { value: 'ATL', label: 'Atlanta' },
              { value: 'BOS', label: 'Boston' },
              { value: 'CHI', label: 'Chicago' },
              {/* etc */}
            ]}
            multiple
          />
        `}
      >
        <Spacer size="medium">
          <Select
            options={[
              { value: 'ATL', label: 'Atlanta' },
              { value: 'BOS', label: 'Boston' },
              { value: 'CHI', label: 'Chicago' },
              { value: 'DEN', label: 'Denver' },
              { value: 'LA', label: 'Los Angeles' },
              { value: 'MIA', label: 'Miami' },
              { value: 'NYC', label: 'New York City' },
              { value: 'OMA', label: 'Omaha' },
              { value: 'ORL', label: 'Orlando' },
              { value: 'SF', label: 'San Francisco' },
            ]}
          />
          <Select
            options={[{ value: 'NYC', label: 'New York City' }]}
            disabled
          />
          <Select
            options={[
              { value: 'ATL', label: 'Atlanta' },
              { value: 'BOS', label: 'Boston' },
              { value: 'CHI', label: 'Chicago' },
              { value: 'DEN', label: 'Denver' },
              { value: 'LA', label: 'Los Angeles' },
              { value: 'MIA', label: 'Miami' },
              { value: 'NYC', label: 'New York City' },
              { value: 'OMA', label: 'Omaha' },
              { value: 'ORL', label: 'Orlando' },
              { value: 'SF', label: 'San Francisco' },
            ]}
            multiple
          />
        </Spacer>
      </ComponentExample>
    </PageSection>
  </>
);
