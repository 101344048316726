import React from 'react';

import { Spacer } from 'lib';
import { PageSection } from 'site/shared';

const Square = ({ elevation, ...props }) => (
  <span
    {...props}
    style={{
      display: 'inline-flex',
      width: '100px',
      height: '100px',
      boxShadow: `var(--elevation-${elevation})`,
    }}
  />
);

export default () => (
  <PageSection title="Elevation">
    <Spacer size="large">
      <Square elevation="1" />
      <Square elevation="2" />
      <Square elevation="3" />
      <Square elevation="4" />
      <Square elevation="5" />
    </Spacer>
  </PageSection>
);
