import React from 'react';
import { node, oneOf } from 'prop-types';
import classNames from 'classnames';

import styles from './Spacer.module.css';

export const Spacer = ({ children, direction, size }) =>
  React.Children.map(children, (child, i) => (
    <>
      {i > 0 && (
        <span
          className={classNames(styles.spacer, styles[direction], {
            [styles.small]: size === 'small',
            [styles.medium]: size === 'medium',
            [styles.large]: size === 'large',
          })}
        />
      )}
      {child}
    </>
  ));

Spacer.propTypes = {
  children: node.isRequired,
  direction: oneOf(['row', 'column']),
  size: oneOf(['small', 'medium', 'large']),
};

Spacer.defaultProps = {
  direction: 'row',
  size: 'small',
};
