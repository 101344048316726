import React from 'react';
import { node } from 'prop-types';

import styles from './DescriptionListItem.module.css';

export const DescriptionListItem = ({ children, label }) => (
  <>
    <dt className={styles.label}>{label}</dt>
    <dd className={styles.value}>{children}</dd>
  </>
);

DescriptionListItem.propTypes = {
  children: node,
  label: node,
};

DescriptionListItem.defaultProps = {
  children: undefined,
  label: undefined,
};
