import { makeIcon } from './Icon';

import { ReactComponent as Alert } from './shapes/alert.svg';
import { ReactComponent as AlertCircle } from './shapes/alert-circle.svg';
import { ReactComponent as Caret } from './shapes/caret.svg';
import { ReactComponent as Check } from './shapes/check.svg';
import { ReactComponent as CheckCircle } from './shapes/check-circle.svg';
import { ReactComponent as Chevron } from './shapes/chevron.svg';
import { ReactComponent as Close } from './shapes/close.svg';
import { ReactComponent as CloseCircle } from './shapes/close-circle.svg';
import { ReactComponent as Info } from './shapes/info.svg';
import { ReactComponent as Trash } from './shapes/trash.svg';

export const IconAlert = makeIcon(Alert);
export const IconAlertCircle = makeIcon(AlertCircle);
export const IconCaret = makeIcon(Caret);
export const IconCheck = makeIcon(Check);
export const IconCheckCircle = makeIcon(CheckCircle);
export const IconChevron = makeIcon(Chevron);
export const IconClose = makeIcon(Close);
export const IconCloseCircle = makeIcon(CloseCircle);
export const IconInfo = makeIcon(Info);
export const IconTrash = makeIcon(Trash);
