import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import styles from './PageSection.module.css';

export const PageSection = ({ children, className, title, ...props }) => (
  <section {...props} className={classNames(styles.section, className)}>
    <h1 className={styles.title}>{title}</h1>
    {children}
  </section>
);

PageSection.propTypes = {
  title: node.isRequired,
  children: node,
  className: string,
};

PageSection.defaultProps = {
  children: undefined,
  className: undefined,
};
