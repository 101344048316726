import React from 'react';

import * as icons from 'lib/Icon';
import { Table, Spacer } from 'lib';
import { ComponentExample, PageSection, PageTitle } from 'site/shared';

const { IconAlert } = icons;

export const Icons = () => (
  <>
    <PageTitle>Icons</PageTitle>

    <PageSection title="Usage">
      <ComponentExample
        snippet={`
        <IconAlert size="small" />
        <IconAlert size="medium" />
        <IconAlert size="large" />
      `}
      >
        <Spacer size="medium">
          <IconAlert size="small" />
          <IconAlert size="medium" />
          <IconAlert size="large" />
        </Spacer>
      </ComponentExample>
    </PageSection>

    <PageSection title="Icon set">
      <Table
        columns={['Icon', 'Small', 'Medium', 'Large']}
        rows={Object.entries(icons).map(([name, Icon]) => [
          name.replace(/^Icon/, ''),
          <Icon size="small" />,
          <Icon size="medium" />,
          <Icon size="large" />,
        ])}
      />
    </PageSection>
  </>
);
