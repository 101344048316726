import React from 'react';

import { RadioButton, Spacer } from 'lib';
import { ComponentExample, PageSection } from 'site/shared';

export default () => (
  <>
    <PageSection title="Radio buttons">
      <ComponentExample
        title="Normal"
        snippet={`
          <RadioButton label="Unchecked" />
          <RadioButton label="Checked" checked />
          <RadioButton label="Unchecked and disabled" disabled />
          <RadioButton label="Checked and disabled" disabled checked />
        `}
      >
        <Spacer direction="column">
          <RadioButton label="Unchecked" name="radio-default" />
          <RadioButton label="Checked" name="radio-default" defaultChecked />
          <RadioButton
            label="Unchecked and disabled"
            name="radio-default-disabled"
            disabled
          />
          <RadioButton
            label="Checked and disabled"
            name="radio-default-disabled"
            disabled
            defaultChecked
          />
        </Spacer>
      </ComponentExample>

      <ComponentExample
        title="Contained"
        snippet={`
          <RadioButton label="Unchecked" variant="contained" />
          <RadioButton label="Checked" variant="contained" checked />
          <RadioButton label="Unchecked and disabled" variant="contained" disabled />
          <RadioButton label="Checked and disabled" variant="contained" disabled checked />
        `}
      >
        <Spacer direction="column">
          <RadioButton
            label="Unchecked"
            variant="contained"
            name="radio-contained"
          />
          <RadioButton
            label="Checked"
            variant="contained"
            name="radio-contained"
            defaultChecked
          />
          <RadioButton
            label="Unchecked and disabled"
            variant="contained"
            name="radio-contained-disabled"
            disabled
          />
          <RadioButton
            label="Checked and disabled"
            variant="contained"
            name="radio-contained-disabled"
            disabled
            defaultChecked
          />
        </Spacer>
      </ComponentExample>
    </PageSection>
  </>
);
