import React, { useEffect, useRef, forwardRef } from 'react';
import { bool, string, oneOf } from 'prop-types';
import classNames from 'classnames';

import { IconCheck } from 'lib';
import styles from './Checkbox.module.css';

let currentId = 0;

export const Checkbox = forwardRef(
  ({ label, id, indeterminate, variant, ...props }, ref) => {
    const idRef = useRef({ id: id || `checkbox-${currentId++}` });
    const inputRef = ref || useRef();

    useEffect(
      () => {
        if (inputRef.current) {
          inputRef.current.indeterminate = indeterminate; // eslint-disable-line no-param-reassign
        }
      },
      [indeterminate],
    );

    return (
      <label
        className={classNames(styles.label, styles[variant], {
          [styles.disabled]: props.disabled, // eslint-disable-line react/destructuring-assignment
        })}
        htmlFor={idRef.current.id}
      >
        <input
          {...props}
          type="checkbox"
          className={styles.input}
          id={idRef.current.id}
          ref={inputRef}
        />
        <span className={styles.box}>
          <IconCheck className={styles.icon} />
        </span>
        {label && <span className={styles.text}>{label}</span>}
      </label>
    );
  },
);

Checkbox.propTypes = {
  id: string,
  indeterminate: bool,
  label: string,
  variant: oneOf(['default', 'contained']),
};

Checkbox.defaultProps = {
  id: undefined,
  indeterminate: false,
  label: undefined,
  variant: 'default',
};
