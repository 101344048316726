import React from 'react';
import { node } from 'prop-types';

import styles from './NavBar.module.css';

export const NavBar = ({ children }) => (
  <nav className={styles.container}>{children}</nav>
);

NavBar.propTypes = {
  children: node.isRequired,
};
