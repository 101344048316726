import React from 'react';
import { node, string, oneOf } from 'prop-types';
import classNames from 'classnames';

import styles from './Button.module.css';

/* eslint-disable react/button-has-type */
export const Button = ({ children, type, variant, ...props }) => (
  <button
    {...props}
    type={type}
    className={classNames(styles.button, styles[variant])}
  >
    {children}
  </button>
);
/* eslint-enable react/button-has-type */

Button.propTypes = {
  children: node.isRequired,
  type: string,
  variant: oneOf(['primary', 'secondary', 'tertiary', 'icon']),
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
};
