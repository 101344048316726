import React from 'react';

import { Button, Spacer, IconTrash } from 'lib';
import { ComponentExample, PageSection } from 'site/shared';

export default () => (
  <>
    <PageSection title="Button">
      <ComponentExample
        title="Variants"
        snippet={`
          <Button>Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="tertiary">Tertiary</Button>
          <Button variant="icon"><IconTrash size="medium" /></Button>
        `}
      >
        <Spacer size="medium">
          <Button>Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="tertiary">Tertiary</Button>
          <Button variant="icon">
            <IconTrash size="medium" />
          </Button>
        </Spacer>
      </ComponentExample>
    </PageSection>
  </>
);
