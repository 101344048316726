import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NavBar, NavBarLink } from 'lib';

import { Home } from './Home';
import { DesignSystem } from './DesignSystem';
import { Components } from './Components';
import { Icons } from './Icons';
import styles from './App.module.css';

const routes = [
  { title: 'Home', href: '/', render: Home, exact: true },
  { title: 'Design System', href: '/design-system', render: DesignSystem },
  { title: 'Components', href: '/components', render: Components },
  { title: 'Icons', href: '/icons', render: Icons },
];

export const App = () => (
  <BrowserRouter>
    <>
      <NavBar>
        {routes.map(({ title, href, exact }) => (
          <NavBarLink key={title} to={href} exact={exact}>
            {title}
          </NavBarLink>
        ))}
      </NavBar>
      <div className={styles.container}>
        <Switch>
          {routes.map(({ title, href, render, exact }) => (
            <Route key={title} path={href} render={render} exact={exact} />
          ))}
        </Switch>
      </div>
    </>
  </BrowserRouter>
);
