import React from 'react';
import {
  arrayOf,
  node,
  bool,
  shape,
  oneOfType,
  string,
  func,
} from 'prop-types';
import classNames from 'classnames';

import styles from './Table.module.css';
import { TableRow } from './TableRow';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';

export const Table = ({ children, columns, fullWidth, rows }) => (
  <table
    className={classNames(styles.table, {
      [styles.fullWidth]: fullWidth,
    })}
  >
    <thead>
      <TableRow>
        {columns.map(column => {
          if (typeof column === 'string') {
            return <TableHeader key={column}>{column}</TableHeader>;
          }

          const Header = column.cell || TableHeader;
          return <Header key={column.key}>{column.header}</Header>;
        })}
      </TableRow>
    </thead>
    <tbody>
      {children ||
        rows.map((row, rowIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={rowIndex}>
            {columns.map((column, columnIndex) => {
              if (typeof column === 'string') {
                return <TableCell key={column}>{row[columnIndex]}</TableCell>;
              }

              const Cell = column.cell || TableCell;
              return <Cell key={column.key}>{row[columnIndex]}</Cell>;
            })}
          </TableRow>
        ))}
    </tbody>
  </table>
);

Table.propTypes = {
  columns: arrayOf(
    oneOfType([
      string,
      shape({
        key: string.isRequired,
        cell: func,
        header: node,
      }),
    ]),
  ).isRequired,
  children: node,
  fullWidth: bool,
  rows: arrayOf(arrayOf(node)),
};

Table.defaultProps = {
  children: undefined,
  fullWidth: false,
  rows: [],
};
