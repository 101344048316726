import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Prism } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/styles/prism';

import styles from './ComponentExample.module.css';

const reindent = code => {
  const lines = code
    .split('\n')
    .filter(
      (line, index, array) =>
        !(line.trim() === '' && (index === 0 || index === array.length - 1)),
    );
  const nonblankLines = lines.filter(line => line.trim() !== '');
  if (!nonblankLines.length) return code;

  const indent = nonblankLines[0].search(/\S/);
  const indentRegex = new RegExp(`^\\s{${indent}}`);
  return lines.map(line => line.replace(indentRegex, '')).join('\n');
};

export const ComponentExample = ({
  children,
  className,
  snippet,
  title,
  ...props
}) => (
  <section {...props} className={classNames(styles.container, className)}>
    {title && <h1 className={styles.title}>{title}</h1>}
    <div className={styles.stage}>{children}</div>
    {snippet && (
      <Prism
        language="jsx"
        style={coy}
        className={styles.pre}
        codeTagProps={{ className: styles.code }}
      >
        {reindent(snippet)}
      </Prism>
    )}
  </section>
);

ComponentExample.propTypes = {
  children: node,
  className: string,
  snippet: string,
  title: string,
};

ComponentExample.defaultProps = {
  children: undefined,
  className: undefined,
  snippet: undefined,
  title: undefined,
};
