import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames';

// import styles from './TableRow.module.css';

export const TableRow = ({ children, className, header, ...props }) => (
  <tr {...props} className={classNames(className)}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: node,
  className: string,
  header: bool,
};

TableRow.defaultProps = {
  children: undefined,
  className: undefined,
  header: false,
};
