import React from 'react';

import { Badge, Spacer } from 'lib';
import { ComponentExample, PageTitle } from 'site/shared';

export default () => (
  <>
    <PageTitle>Badge</PageTitle>

    <ComponentExample
      snippet={`
        <Badge variant="success">Success</Badge>
        <Badge variant="warning">Warning</Badge>
        <Badge variant="error">Error</Badge>
        <Badge variant="info">Info</Badge>
      `}
    >
      <Spacer>
        <Badge variant="success">Success</Badge>
        <Badge variant="warning">Warning</Badge>
        <Badge variant="error">Error</Badge>
        <Badge variant="info">Info</Badge>
      </Spacer>
    </ComponentExample>
  </>
);
