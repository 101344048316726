import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import styles from './TableCell.module.css';

export const TableCell = ({ children, className, ...props }) => (
  <td {...props} className={classNames(styles.td, className)}>
    {children}
  </td>
);

TableCell.propTypes = {
  children: node,
  className: string,
};

TableCell.defaultProps = {
  children: undefined,
  className: undefined,
};
