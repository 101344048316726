import React, { useRef, forwardRef } from 'react';
import { string, oneOf } from 'prop-types';
import classNames from 'classnames';

import styles from './RadioButton.module.css';

let currentId = 0;

export const RadioButton = forwardRef(
  ({ id, label, variant, ...props }, ref) => {
    const idRef = useRef({ id: id || `radio-${currentId++}` });

    return (
      <label
        className={classNames(styles.label, styles[variant])}
        htmlFor={idRef.current.id}
      >
        <input
          {...props}
          ref={ref}
          type="radio"
          className={styles.input}
          id={idRef.current.id}
        />
        <span className={styles.circle} />
        {label && <span className={styles.text}>{label}</span>}
      </label>
    );
  },
);

RadioButton.propTypes = {
  id: string,
  label: string,
  variant: oneOf(['default', 'contained']),
};

RadioButton.defaultProps = {
  id: undefined,
  label: undefined,
  variant: 'default',
};
