import React from 'react';
import { node, string, oneOfType, func } from 'prop-types';
import classNames from 'classnames';

import styles from './PageTitle.module.css';

export const PageTitle = ({
  children,
  className,
  component: Component,
  ...props
}) => (
  <Component {...props} className={classNames(styles.title, className)}>
    {children}
  </Component>
);

PageTitle.propTypes = {
  children: node,
  className: string,
  component: oneOfType([string, func]),
};

PageTitle.defaultProps = {
  children: undefined,
  className: undefined,
  component: 'h1',
};
