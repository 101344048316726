import React from 'react';
import { oneOf } from 'prop-types';
import classNames from 'classnames';

import styles from './Icon.module.css';

export const makeIcon = Component => {
  const Icon = ({ size, className, ...props }) => (
    <Component
      {...props}
      className={classNames(styles.icon, { [styles[size]]: size }, className)}
    />
  );

  Icon.propTypes = {
    size: oneOf(['small', 'medium', 'large']),
  };

  Icon.defaultProps = {
    size: undefined,
  };

  return Icon;
};
