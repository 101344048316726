import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import styles from './TableHeader.module.css';

export const TableHeader = ({ children, className, ...props }) => (
  <th {...props} className={classNames(styles.th, className)}>
    {children}
  </th>
);

TableHeader.propTypes = {
  children: node,
  className: string,
};

TableHeader.defaultProps = {
  children: undefined,
  className: undefined,
};
