import React from 'react';

import { PageTitle } from 'site/shared';
import Colors from './sections/Colors';
import Elevation from './sections/Elevation';

export const DesignSystem = () => (
  <>
    <PageTitle>Design System</PageTitle>

    <Colors />
    <Elevation />
  </>
);
