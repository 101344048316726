import React from 'react';

import { PageTitle } from 'site/shared';

import styles from './Home.module.css';

export const Home = () => (
  <>
    <PageTitle>Fluid</PageTitle>
    <p>
      Fluid is a UI library I designed and developed primarily as a learning
      exercise but also for use in side projects.
    </p>
    <code className={styles.code}>npm install @mturco/fluid</code>
  </>
);
